@import "variables";
@import "./versions/2.0.6/base/scss/meetingSubscriptionFlowVariant1";
@import "./versions/2.0.6/base/scss/meetingVariant1";
@import "./versions/2.0.6/base/scss/datepicker";
// Theme - only 1 entry possible!!
@import "./versions/2.0.6/#themes/neptunus";

// inject:defaultWidgets:scss
@import "./versions/2.0.6/defaultWidgets/accordionVariant1/scss/_accordionVariant1.scss";
@import "./versions/2.0.6/defaultWidgets/addFunctionVariant1/scss/_addFunctionVariant1.scss";
@import "./versions/2.0.6/defaultWidgets/bannerVariant1/scss/_bannerVariant1.scss";
@import "./versions/2.0.6/defaultWidgets/changeAttributesVariant1/scss/_changeAttributesVariant1.scss";
@import "./versions/2.0.6/defaultWidgets/changeFunctionVariant1/scss/_changeFunctionVariant1.scss";
@import "./versions/2.0.6/defaultWidgets/changePasswordVariant1/scss/_changePasswordVariant1.scss";
@import "./versions/2.0.6/defaultWidgets/changePersonalInformationVariant1/scss/_changePersonInformationVariant1.scss";
@import "./versions/2.0.6/defaultWidgets/documentsVariant1/scss/_documentsVariant1.scss";
@import "./versions/2.0.6/defaultWidgets/documentDetailVariant1/scss/_documentDetailVariant1.scss";
@import "./versions/2.0.6/defaultWidgets/footerVariant1/scss/_footerVariant1.scss";
@import "./versions/2.0.6/defaultWidgets/footerVariant2/scss/_footerVariant2.scss";
@import "./versions/2.0.6/defaultWidgets/footerVariant3/scss/_footerVariant3.scss";
@import "./versions/2.0.6/defaultWidgets/formVariant1/scss/_formVariant1.scss";
@import "./versions/2.0.6/defaultWidgets/formV3Variant1/scss/_formV3Variant1.scss";
@import "./versions/2.0.6/defaultWidgets/galleryVariant1/scss/_galleryVariant1.scss";
@import "./versions/2.0.6/defaultWidgets/headerVariant1/scss/_headerVariant1.scss";
@import "./versions/2.0.6/defaultWidgets/highlightVariant1/scss/_highlightVariant1.scss";
@import "./versions/2.0.6/defaultWidgets/loginVariant1/scss/_loginVariant1.scss";
@import "./versions/2.0.6/defaultWidgets/meetingsVariant1/scss/_meetingsVariant1.scss";
@import "./versions/2.0.6/defaultWidgets/myPersonalInformationVariant1/scss/_myPersonalInformationVariant1.scss";
@import "./versions/2.0.6/defaultWidgets/pagesSmallVariant1/scss/_pagesSmallVariant1.scss";
@import "./versions/2.0.6/defaultWidgets/pagesVariant1/scss/_pagesVariant1.scss";
@import "./versions/2.0.6/defaultWidgets/pagesVariant2/scss/_pagesVariant2.scss";
@import "./versions/2.0.6/defaultWidgets/pagesVariant5/scss/_pagesVariant5.scss";
@import "./versions/2.0.6/defaultWidgets/pagesVariant6/scss/_pagesVariant6.scss";
@import "./versions/2.0.6/defaultWidgets/parallaxVariant1/scss/_parallaxVariant1.scss";
@import "./versions/2.0.6/defaultWidgets/personPersonalInformationVariant1/scss/_personPersonalInformationVariant1.scss";
@import "./versions/2.0.6/defaultWidgets/pollVariant1/scss/_pollVariant1.scss";
@import "./versions/2.0.6/defaultWidgets/removeFunctionVariant1/scss/_removeFunctionVariant1.scss";
@import "./versions/2.0.6/defaultWidgets/resetPasswordVariant1/scss/_resetPasswordVariant1.scss";
@import "./versions/2.0.6/defaultWidgets/searchVariant1/scss/_searchVariant1.scss";
@import "./versions/2.0.6/defaultWidgets/sidebarVariant1/scss/_sidebarVariant1.scss";
@import "./versions/2.0.6/defaultWidgets/sliderVariant1/scss/_sliderVariant1.scss";
@import "./versions/2.0.6/defaultWidgets/sliderVariant2/scss/_sliderVariant2.scss";
@import "./versions/2.0.6/defaultWidgets/subscribeNewsletterVariant1/scss/_subscribeNewsletterVariant1.scss";
@import "./versions/2.0.6/defaultWidgets/textVariant1/scss/_textVariant1.scss";
@import "./versions/2.0.6/defaultWidgets/twitterVariant1/scss/_twitterVariant1.scss";
@import "./versions/2.0.6/defaultWidgets/videoVariant1/scss/_videoVariant1.scss";
@import "./versions/2.0.6/defaultWidgets/welcomeVariant1/scss/_welcomeVariant1.scss";
@import "./versions/2.0.6/defaultWidgets/welcomeVariant2/scss/_welcomeVariant2.scss";
@import "./versions/2.0.6/defaultWidgets/workgroupDocumentsVariant1/scss/_workgroupDocumentsVariant1.scss";
@import "./versions/2.0.6/defaultWidgets/workgroupMeetingDetailVariant1/scss/_workgroupMeetingDetailVariant1.scss";
@import "./versions/2.0.6/defaultWidgets/workgroupsVariant2/scss/_workgroupsVariant2.scss";
// endinject:defaultWidgets

// inject:specialWidgets:scss
// endinject:specialWidgets

// inject:kollaWidgets:scss
// endinject:kollaWidgets
